import React from "react";
import Swiper from "./swiper";
import Testimonial from "./Testimonial";
import Testimonials from "./Testimonials";

const Components = {
  Swiper: Swiper,
  Testimonial: Testimonial,
  Testimonials: Testimonials
};


const block = (block) => {
  if (typeof Components[block.name] !== "undefined") {
    return React.createElement(Components[block.name], {
      block: block.name,
      param: block.param,
      data: block.data
    });
  }
  return React.createElement(
    () => <div>The component {block.name} has not been created yet.</div>
  );
};

export default block