import { useStaticQuery, graphql } from "gatsby" 
import React from "react" 
import Components from "./components.js";

const Testimonial = (props) => {
      
    const data = useStaticQuery(graphql`
      query Quotes {
        scomos {
          articleFindAll(category:"reference",limit:1, orderBy:"rand()") {
            id
            name
            prologue
          }
        }
      }
    `)    
    

  return (
    <>
     {data.scomos.articleFindAll.map((child) => { 
          const  component = {
              name: 'Testimonials',
              data: child,
              param: 1
          }
          return Components(component) 
      })}
        
  </>
  )
};
export default Testimonial;